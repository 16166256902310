<template>
  <v-layout column>
    <v-layout justify-space-between align-center pa-3>
      <h4 style="font-size: 20px">Areas of Interest</h4>
    </v-layout>
    <v-flex style="height: 600px" pa-3>
      <v-form ref="areas-of-interest-form">
        <CRInput
          v-model="name"
          label="Name"
          placeholder="New Area Name"
          :rules="[(val) => !!val || 'Area name is required']"
        />
        <CRSelect
          v-model="type"
          :items="areaTypeOptions"
          item-value="key"
          item-text="label"
          label="Area Type"
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Area Type Required',
              error: 'Area Type Required',
            }),
          ]"
        />
      </v-form>
      <v-layout row justify-end>
        <CRButton
          id="areas-of-interest-cancel-btn"
          class="areas-of-interest-cancel-btn mr-3"
          color="primary"
          @click="$emit('close-form')"
        >
          Cancel
        </CRButton>
        <CRButton
          id="areas-of-interest-cancel-btn"
          class="areas-of-interest-save-btn"
          color="primary"
          @click="onSaveArea"
        >
          Save
        </CRButton>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { createGeofence, updateGeofence } from '@/services/geofences'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    geofence: {
      type: Object,
      default: null,
    },
  },
  emits: ['close-form', 'geofence-refresh'],
  data() {
    return {
      name: '',
      type: null,
      geoFenceCoordinates: [],
    }
  },
  computed: {
    ...mapGetters({
      areaTypeOptions: 'types/geofenceTypes',
    }),
  },
  watch: {
    geofence: {
      handler(newVal) {
        this.geoFenceCoordinates = newVal.geoFenceCoordinates
      },
      deep: true,
    },
  },
  created() {
    if (this.geofence) {
      this.name = this.geofence?.name || ''
      this.type = this.geofence?.geoFenceType?.key || null
      this.geoFenceCoordinates = this.geofence.geoFenceCoordinates
    }
  },
  methods: {
    isRequired,
    isNotEmpty,
    createGeofence,
    updateGeofence,
    async onSaveArea() {
      const isValid = this.$refs['areas-of-interest-form'].validate()
      if (!isValid) {
        return
      }
      const payload = {
        name: this.name,
        geoFenceType: this.areaTypeOptions.find(
          (type) => type.key === this.type
        ),
        isActive: true,
        geoFenceCoordinates: this.geoFenceCoordinates,
      }
      if (this.geofence.hasOwnProperty('editing')) {
        await this.updateGeofence(this.geofence.id, payload)
      } else {
        await this.createGeofence(payload)
      }
      this.$emit('close-form')
      EventBus.$emit('geofence-refresh')
    },
  },
}
</script>
