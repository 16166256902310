<template>
  <GmapMap
    ref="gMap"
    class="map"
    :zoom="mapConfig.zoom"
    :center="mapConfig.center"
    :options="mapConfig.options"
  >
    <AreaOfInterest
      v-for="(geofence, geofenceId) in geofences"
      :key="geofenceId"
      :editing="geofence.editing"
      :geofence="geofence"
    />
    <AreaOfInterest v-if="creating" :editing="true" :geofence="editingArea" />
  </GmapMap>
</template>

<script>
import AreaOfInterest from './AreaOfInterest.vue'
import { mapStyles } from './mapStyles.js'
import { EventBus } from '@/utils/event-bus'
import { gmapApi } from 'vue2-google-maps'

export default {
  components: {
    AreaOfInterest,
  },
  props: {
    geofences: {
      type: Object,
      default: () => {},
    },
    creating: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:area-coordinates'],
  data() {
    return {
      map: null,
      editingArea: null,
      mapConfig: {
        center: { lat: 33.8458485, lng: -84.3716548 },
        zoom: 18,
        options: {
          clickableIcons: false,
          streetViewControl: false,
          fullScreenControl: true,
          mapTypeControl: false,
          styles: mapStyles,
          fullscreenControlOptions: {
            position: -1,
          },
          gestureHandling: 'greedy',
          minZoom: 2,
          maxZoom: 20,
        },
      },
    }
  },
  computed: {
    google: gmapApi,
  },
  watch: {
    creating(isCreating) {
      if (isCreating) {
        this.addPath()
      } else {
        this.editingArea = null
      }
    },
  },
  async mounted() {
    this.map = await this.$refs.gMap.$mapPromise
    EventBus.$on('handle-recenter-geofence', (geofenceId) => {
      this.handleRecenter(geofenceId)
    })
  },
  methods: {
    addPath() {
      // obtain the bounds, so we can guess how big the polygon should be
      const bounds = this.$refs.gMap.$mapObject.getBounds()
      const northEast = bounds.getNorthEast()
      const southWest = bounds.getSouthWest()
      const center = bounds.getCenter()
      const degree = 3
      const f = Math.pow(0.66, degree)

      // Draw a triangle. Use f to control the size of the triangle.
      // i.e., every time we add a path, we reduce the size of the triangle
      const path = [
        {
          lng: center.lng(),
          lat: (1 - f) * center.lat() + f * northEast.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * southWest.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
        {
          lng: (1 - f) * center.lng() + f * northEast.lng(),
          lat: (1 - f) * center.lat() + f * southWest.lat(),
        },
      ]

      this.editingArea = { geoFenceCoordinates: path }
    },
    handleRecenter(geofenceId) {
      const geofence = this.geofences[geofenceId]
      if (geofence != null && geofence.geoFenceCoordinates.length > 2) {
        let bounds = new google.maps.LatLngBounds()
        for (const coordinate of geofence.geoFenceCoordinates) {
          bounds.extend({
            lat: Number(coordinate.lat),
            lng: Number(coordinate.lng),
          })
        }
        this.map.fitBounds(bounds)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
  z-index: 0;
}
</style>
