<template>
  <v-layout column>
    <v-layout justify-space-between align-center pa-3>
      <h4 style="font-size: 20px">Areas of Interest</h4>
      <CRButton
        id="affiliate-adjustment-sidebar-delete-btn"
        class="affiliate-adjustment-sidebar--delete-btn"
        color="primary"
        @click="$emit('create-area')"
      >
        Create New Area
      </CRButton>
    </v-layout>
    <v-flex style="height: 600px; overflow-y: auto">
      <template v-if="loading">
        <v-flex v-for="i in [0, 1, 3]" :key="i" class="geofence-card">
          <SkeletonBox style="height: 200px" />
        </v-flex>
      </template>
      <template v-else>
        <v-flex
          v-for="(geofence, geofenceId, geofenceIndex) in geofences"
          :key="geofenceId"
          class="geofence-card"
        >
          <v-layout column>
            <v-row style="display: flex; align-items: center; margin: 5px">
              <span
                style="display: flex; align-items: center; cursor: pointer"
                @click="handleRecenter(geofenceId)"
              >
                <p style="margin-right: 10px">
                  {{ geofenceIndex + 1 }}
                </p>
                <p style="font-weight: 600; font-size: 18px">
                  {{ geofence.name }}
                </p>
                <CRTag
                  :label="geofence.geoFenceType.label"
                  :color="getGeofenceColor(geofence)"
                  style="margin-left: 10px; margin-top: 2px"
                />
              </span>
              <CRIcon
                color="primary"
                style="cursor: pointer; margin-left: auto"
                view-box="0 0 24 24"
                width="15"
                height="15"
                small
                @click.native="handleEdit(geofenceId)"
              >
                edit
              </CRIcon>
              <CRIcon
                :color="$cr.theme.grayBase"
                style="cursor: pointer; margin-left: 10px"
                view-box="0 0 24 24"
                width="15"
                height="15"
                small
                @click.native="openDeleteGeofenceSidebar(geofenceId)"
              >
                close
              </CRIcon>
            </v-row>
          </v-layout>
        </v-flex>
      </template>
    </v-flex>
  </v-layout>
</template>

<script>
import SkeletonBox from '@/components/SkeletonBox.vue'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    SkeletonBox,
  },
  props: {
    geofences: {
      type: Object,
      required: true,
    },
  },
  emits: ['create-area', 'edit-area'],
  methods: {
    openDeleteGeofenceSidebar(geofenceId) {
      const component = () => import('./DeleteGeofenceSidebar.vue')
      const {
        id,
        name,
        coordinates,
        geoFenceCoordinates,
        geoFenceType,
        isActive,
      } = this.geofences[geofenceId]
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Delete Area of Interest',
          id,
          name,
          coordinates,
          geoFenceCoordinates,
          geoFenceType,
          isActive,
        },
        component,
      })
    },
    getGeofenceColor(geofence) {
      switch (geofence?.geoFenceType?.key) {
        case 'mountain':
          return 'green'
        case 'border':
          return 'red'
        case 'tollbooth':
          return 'blue'
        case 'event':
          return 'yellow'
        case 'general':
          return 'purple'
        default:
          return 'gray'
      }
    },
    handleEdit(geofenceId) {
      this.$emit('edit-area', geofenceId)
      EventBus.$emit('handle-recenter-geofence', geofenceId)
    },
    handleRecenter(geofenceId) {
      EventBus.$emit('handle-recenter-geofence', geofenceId)
    },
  },
}
</script>

<style lang="scss" scoped>
.geofence-card {
  border: 1px solid $input-border-gray;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 15px;
  padding: 10px;

  p {
    color: $black;
    margin: 0;
  }
}
</style>
