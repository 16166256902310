<template>
  <gmap-polygon
    :paths="paths"
    :editable="editing"
    :draggable="editing"
    :options="polygonOptions"
    @paths_changed="updateEdited($event)"
  ></gmap-polygon>
</template>

<script>
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    geofence: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['update:edited-area'],
  data() {
    return {
      paths: [],
      savedPaths: [],
    }
  },
  computed: {
    polygonColor() {
      switch (this.geofence?.geoFenceType?.key) {
        case 'mountain':
          return this.$cr.theme.green
        case 'border':
          return this.$cr.theme.red
        case 'tollbooth':
          return this.$cr.theme.blue
        case 'event':
          return this.$cr.theme.yellow
        case 'general':
          return this.$cr.theme.purple
        default:
          return this.$cr.theme.gray
      }
    },
    polygonOptions() {
      return {
        fillColor: this.polygonColor,
        fillOpacity: 0.2,
        strokeColor: '#000000',
        strokeOpacity: 0.4,
        strokeWeight: 2,
      }
    },
  },
  watch: {
    editing(isEditing) {
      if (!isEditing) {
        this.paths = this.savedPaths
      }
    },
  },
  mounted() {
    this.convertCoordinates()
  },
  methods: {
    convertCoordinates() {
      const { geoFenceCoordinates } = this.geofence
      this.savedPaths = geoFenceCoordinates.map((coordinate) => {
        return {
          lat: Number(coordinate.lat),
          lng: Number(coordinate.lng),
        }
      })
      this.paths = this.savedPaths
    },
    updateEdited(mvcArray) {
      const paths = []
      for (let j = 0; j < mvcArray.getAt(0).getLength(); j++) {
        let point = mvcArray.getAt(0).getAt(j)
        paths.push({ lat: point.lat(), lng: point.lng(), orderIndex: j })
      }
      this.paths = paths
      EventBus.$emit('edited-area', paths)
    },
  },
}
</script>
