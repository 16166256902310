<template>
  <Sheet>
    <div style="position: relative; margin-left: 7px">
      <h1 style="display: inline-block; font-size: 24px">Pricing Map</h1>
      <v-layout row>
        <v-flex shrink xs4>
          <AreasOfInterestForm
            v-if="isFormOpen"
            :geofence="geofence"
            :editing="editing"
            @close-form="onCloseForm"
          />
          <AreasOfInterestList
            v-if="!isFormOpen"
            :geofences="geofences"
            @create-area="creating = true"
            @edit-area="onEditArea"
          />
        </v-flex>
        <v-flex grow xs8>
          <AreasOfInterestMap
            id="areasOfInterestMap"
            ref="areasOfInterestMap"
            :geofences="geofences"
            :refresh-interval="standardRefreshInterval"
            :creating="creating"
          />
        </v-flex>
      </v-layout>
    </div>
  </Sheet>
</template>

<script>
import Sheet from '@/layouts/Sheet.vue'
import AreasOfInterestList from '@/components/AreasOfInterestList.vue'
import AreasOfInterestForm from '@/components/AreasOfInterestForm.vue'
import AreasOfInterestMap from '@/components/AreasOfInterestMap.vue'
import { getAllGeofences } from '@/services/geofences'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    Sheet,
    AreasOfInterestList,
    AreasOfInterestForm,
    AreasOfInterestMap,
  },
  data() {
    return {
      loading: false,
      geofence: null,
      geofences: null,
      creating: false,
      editing: false,
    }
  },
  computed: {
    isFormOpen() {
      return this.creating || this.editing
    },
  },
  mounted() {
    this.refreshGeofences()
    EventBus.$on('geofence-refresh', () => {
      this.refreshGeofences()
    })
    EventBus.$on('edited-area', (e) => {
      this.onAreaCoordinateChange(e)
    })
  },
  methods: {
    async refreshGeofences() {
      this.loading = true
      const { data } = await getAllGeofences()
      const geofences = {}
      const allGeofences = data.data
      for (const geofence of allGeofences) {
        geofence.editing = false
        geofences[geofence.id] = geofence
      }
      this.geofences = geofences
      this.loading = false
    },
    onAreaCoordinateChange(e) {
      if (this.isFormOpen) {
        this.geofence = { ...this.geofence, geoFenceCoordinates: e }
      }
    },
    onEditArea(e) {
      this.editing = true
      this.geofences[e].editing = true
      this.geofence = Object.assign({}, this.geofences[e])
      this.geofences = Object.assign({}, this.geofences)
    },
    onCloseForm() {
      if (this.editing) {
        const { id } = this.geofence
        this.geofences[id].editing = false
        this.geofences = Object.assign({}, this.geofences)
        this.editing = false
      } else {
        this.creating = false
      }
      this.geofence = null
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-section {
  border: 1px solid $gray-light;
  border-radius: 5px;
}

.center-divider {
  border-left: 1px solid $gray-light;
}
</style>
