import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export const getAllGeofences = (): Promise<AxiosResponse<ApiResult>> => {
  const host = baseUrl()
  const url = `https://${host}/geofence`
  return httpService.get(url)
}

export const getGeofenceById = (geofenceId: number): Promise<AxiosResponse<ApiResult>> => {
  const host = baseUrl()
  const url = `https://${host}/geofence/${geofenceId}`
  return httpService.get(url)
}

export const createGeofence = ({
  name,
  geoFenceType,
  isActive,
  geoFenceCoordinates,
}): Promise<AxiosResponse> => {
  const payload = {
    name,
    geoFenceType,
    isActive,
    geoFenceCoordinates,
  }
  const host = baseUrl()
  const url = `https://${host}/geofence`
  return httpService.post(url, payload)
}

export const updateGeofence = (
  geofenceId: number,
  { name, geoFenceType, isActive, geoFenceCoordinates }
): Promise<AxiosResponse<ApiResult>> => {
  const payload = {
    name,
    geoFenceType,
    isActive,
    geoFenceCoordinates,
  }
  const host = baseUrl()
  const url = `https://${host}/geofence/${geofenceId}`
  return httpService.patch(url, payload)
}

export const deleteGeofenceById = (geofenceId: number): Promise<AxiosResponse<ApiResult>> => {
  const host = baseUrl()
  const url = `https://${host}/geofence/${geofenceId}`
  return httpService.delete(url)
}
